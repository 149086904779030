import axios from 'axios';
import qs from 'qs';
import { getAuthToken } from 'utils/authToken';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout: 1000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
});

function request(
  path: string,
  options: { [key: string]: any } = {},
  data: { [key: string]: any } = {}
): Promise<any> {
  const authToken = getAuthToken();
  const config = {
    ...options,
    url: path,
    headers: {
      ...(authToken ? { Authorization: `Bearer ${authToken}` } : authToken)
    },
    data
  };

  return axiosInstance.request(config).catch(error => {
    if (error.response) {
      throw error.response;
    } else if (error.request) {
      throw error.request;
    }
    throw error.message;
  });
}

export function get(path: string, query: { [key: string]: any }): Promise<any> {
  let finalPath = path;
  if (query) {
    finalPath = `${path}?${qs.stringify(query)}`;
  }

  return request(finalPath, { method: 'GET' });
}

export function post(
  path: string,
  data: { [key: string]: any } = {}
): Promise<any> {
  return request(path, { method: 'POST' }, data);
}

export function patch(
  path: string,
  data: { [key: string]: any } = {}
): Promise<any> {
  return request(path, { method: 'PATCH' }, data);
}
