export const CREATE_SUBMISSION = 'containers/Learn#SubmitUnit';
export const SUBMISSION_SUCCESS = 'containers/Learn#SubmitUnit#Success';
export const SUBMISSION_ERROR = 'containers/Learn#SubmitUnit#Error';

export const createSubmission = data => ({
  type: CREATE_SUBMISSION,
  payload: data
});

export const submissionSuccess = res => ({
  type: SUBMISSION_SUCCESS,
  payload: res
});

export const submissionError = error => ({
  type: SUBMISSION_ERROR,
  payload: error
});
