// syllabus
export const GET_SYLLABUS = 'containers/Home/GET_SYLLABUS';
export const GET_SYLLABUS_SUCCESS = 'containers/Home/GET_SYLLABUS_SUCCESS';
export const GET_SYLLABUS_ERROR = 'containers/Home/GET_SYLLABUS_ERROR';

export const getSyllabus = data => ({
  type: GET_SYLLABUS,
  payload: data
});

export const getSyllabusSuccess = res => ({
  type: GET_SYLLABUS_SUCCESS,
  payload: res
});

export const getSyllabusError = error => ({
  type: GET_SYLLABUS_ERROR,
  payload: error
});
