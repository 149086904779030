import { GET_COURSE, GET_COURSE_SUCCESS, GET_COURSE_ERROR } from 'actions';

const initialState = {
  loading: false,
  error: null,
  data: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_COURSE:
      return { ...state, loading: true };
    case GET_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.body.data.attributes
      };
    case GET_COURSE_ERROR:
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
};
