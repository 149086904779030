// @flow

import React from 'react';
import styled from 'styled-components';

import LogoSrc from 'images/logo.svg';

const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const Logo = styled.img`
  width: 200px;
  margin: 24px 0 48px;
`;

const Card = styled.div`
  display: block;
  box-sizing: border-box;
  border: 2px solid ${({ theme }) => theme.palette.grey.faint};
  border-radius: 10px;
  padding: 48px;
  width: 100%;
  @media (min-width: 768px) {
    width: 500px;
  }
`;

const AppLayout = ({ children }) => (
  <Container>
    <Logo src={LogoSrc} />
    <Card hoverable>{children}</Card>
  </Container>
);

export default AppLayout;
