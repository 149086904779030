import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { loadingBarReducer } from 'react-redux-loading-bar';
import signupReducer from './signup';
import loginReducer from './login';
import coursesReducer from './courses';
import courseReducer from './Course';
import contentReducer from './Content';
import enrollmentsReducer from './Enrollment';
import progressReducer from './Progress';
import syllabusReducer from './Syllabus';
import profileReducer from './Profile';
import unitReducer from './Unit';

const appReducers = browserHistory =>
  combineReducers({
    loadingBar: loadingBarReducer,
    router: connectRouter(browserHistory),
    signupReducer,
    loginReducer,
    coursesReducer,
    courseReducer,
    syllabusReducer,
    contentReducer,
    enrollmentsReducer,
    progressReducer,
    unitReducer,
    profileReducer
  });

// Following the recommendation from Dan
// src: https://stackoverflow.com/a/35641992/2636474
const createRootReducer = browserHistory => (state, action) => {
  const computedState = action.type === 'USER_LOGOUT' ? undefined : state;

  return appReducers(browserHistory)(computedState, action);
};

export default createRootReducer;
