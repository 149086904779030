import {
  GET_SYLLABUS,
  GET_SYLLABUS_SUCCESS,
  GET_SYLLABUS_ERROR
} from 'actions';

const initialState = {
  loading: false,
  error: null,
  data: {},
  included: [],
  syllabus: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SYLLABUS:
      return { ...state, loading: true };
    case GET_SYLLABUS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.body.data,
        included: action.payload.body.included,
        syllabus: (function() {
          const response = action.payload.body;
          const chapterType = response.data.relationships.chapters.data.map(
            c => c.type
          )[0];
          const chapters = response.included.filter(
            e => e.type === chapterType
          );
          chapters.forEach((ch, index) => {
            chapters[index].units = response.included.filter(i =>
              ch.relationships.units.data.map(d => d.id).includes(i.id)
            );
          });
          return chapters;
        })()
      };
    case GET_SYLLABUS_ERROR:
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
};
