import { PROGRESS, PROGRESS_SUCCESS, PROGRESS_ERROR } from 'actions';

const initialState = {
  loading: false,
  error: null,
  progress: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PROGRESS:
      return { ...state, loading: true };
    case PROGRESS_SUCCESS:
      return { ...state, loading: false, progress: action.payload.body };
    case PROGRESS_ERROR:
      return { ...state, loading: false, error: action.payload.body.error };
    default:
      return state;
  }
};
