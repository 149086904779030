export const SIGNUP = 'containers/signup/SIGNUP';
export const SIGNUP_SUCCESS = 'containers/signup/SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'containers/signup/SIGNUP_ERROR';

export const signup = data => ({
  type: SIGNUP,
  payload: data
});

export const signupSuccess = res => ({
  type: SIGNUP_SUCCESS,
  payload: res
});

export const signupError = error => ({
  type: SIGNUP_ERROR,
  payload: error
});
