// @flow
import React from 'react';
import ReactDOM from 'react-dom';

import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MUIThemeProvider } from '@material-ui/core/styles';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import App from 'containers/App';
import useTheme from 'theme/mui';
import registerServiceWorker from './registerServiceWorker';

import configureStore, { browserHistory } from './configureStore';

// TODO: Import only the required css
// eslint-disable-next-line
import 'antd/dist/antd.min.css';

import './global.css';

const store = configureStore();

export const AppWithProvider = () => {
  const muiTheme = useTheme();
  return (
    <Provider store={store}>
      <ConnectedRouter history={browserHistory}>
        <ThemeProvider theme={muiTheme}>
          <MUIThemeProvider theme={muiTheme}>
            <App />
          </MUIThemeProvider>
        </ThemeProvider>
      </ConnectedRouter>
    </Provider>
  );
};
ReactDOM.render(<AppWithProvider />, document.getElementById('root'));

registerServiceWorker();

// This is exported for testing purpose.
export default store;
