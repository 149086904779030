import {
  GET_ENROLLMENT,
  GET_ENROLLMENT_SUCCESS,
  GET_ENROLLMENT_ERROR,
  POST_ENROLLMENT,
  POST_ENROLLMENT_SUCCESS,
  POST_ENROLLMENT_ERROR
} from 'actions';

const initialState = {
  loading: false,
  error: null,
  enrollments: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ENROLLMENT:
      return { ...state, loading: true };
    case GET_ENROLLMENT_SUCCESS:
      return { ...state, loading: false, enrollments: action.payload.body };
    case GET_ENROLLMENT_ERROR:
      return { ...state, loading: false, error: true };
    case POST_ENROLLMENT:
      return { ...state, loading: true };
    case POST_ENROLLMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        enrollments: [...state.enrollments, action.payload.body]
      };
    case POST_ENROLLMENT_ERROR:
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
};
