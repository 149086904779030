import { takeLatest, call } from 'redux-saga/effects';
import Request from 'utils/request';
import { GET_PROFILE, getProfileSuccess, getProfileError } from 'actions';

function* getProfile() {
  yield call(Request.get(`/profile`, getProfileSuccess, getProfileError));
}

export default function* getProfileSaga() {
  yield takeLatest(GET_PROFILE, getProfile);
}
