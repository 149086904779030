// GET ENROLLMENT
export const GET_ENROLLMENT = 'GET_ENROLLMENT';
export const GET_ENROLLMENT_SUCCESS = 'GET_ENROLLMENT_SUCCESS';
export const GET_ENROLLMENT_ERROR = 'GET_ENROLLMENT_ERROR';

export const getEnrollment = data => ({
  type: GET_ENROLLMENT,
  payload: data
});

export const getEnrollmentSuccess = res => ({
  type: GET_ENROLLMENT_SUCCESS,
  payload: res
});

export const getEnrollmentError = error => ({
  type: GET_ENROLLMENT_ERROR,
  payload: error
});

// POST ENROLLMENT
export const POST_ENROLLMENT = 'containers/enroll/POST_ENROLLMENT';
export const POST_ENROLLMENT_SUCCESS =
  'containers/enroll/POST_ENROLLMENT_SUCCESS';
export const POST_ENROLLMENT_ERROR = 'containers/enroll/POST_ENROLLMENT_ERROR';

export const postEnrollment = data => ({
  type: POST_ENROLLMENT,
  payload: data
});

export const postEnrollmentSuccess = res => ({
  type: POST_ENROLLMENT_SUCCESS,
  payload: res
});

export const postEnrollmentError = error => ({
  type: POST_ENROLLMENT_ERROR,
  payload: error
});
