export const GET_CONTENT = 'containers/Course/GET_CONTENT';
export const GET_CONTENT_SUCCESS = 'containers/Course/GET_CONTENT_SUCCESS';
export const GET_CONTENT_ERROR = 'containers/Course/GET_CONTENT_ERROR';

export const getContent = data => ({
  type: GET_CONTENT,
  payload: data
});

export const getContentSuccess = res => ({
  type: GET_CONTENT_SUCCESS,
  payload: res
});

export const getContentError = error => ({
  type: GET_CONTENT_ERROR,
  payload: error
});
