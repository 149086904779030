import { takeLatest, call, put } from 'redux-saga/effects';
import { post } from 'utils/apiClient';
import { push } from 'connected-react-router';
import {
  LOGIN,
  LOGIN_SUCCESS,
  loginSuccess,
  loginError,
  getProfile
} from 'actions';
import { setAuthToken } from 'utils/authToken';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

function* login(action) {
  try {
    yield put(showLoading());
    const response = yield call(post, '/login', { user: action.payload });
    yield put(loginSuccess(response));
    yield put(getProfile());
  } catch (error) {
    yield put(loginError(error));
  } finally {
    yield put(hideLoading());
  }
}

function* onSuccess(action) {
  setAuthToken(action.payload.headers.authorization.split(' ')[1]);
  yield put(push('/'));
}

export default function* loginSaga() {
  yield takeLatest(LOGIN, login);
  yield takeLatest(LOGIN_SUCCESS, onSuccess);
}
