export const GET_COURSES = 'containers/Home/GET_COURSES';
export const GET_COURSES_SUCCESS = 'containers/Home/GET_COURSES_SUCCESS';
export const GET_COURSES_ERROR = 'containers/Home/GET_COURSES_ERROR';

export const getCourses = data => ({
  type: GET_COURSES,
  payload: data
});

export const getCoursesSuccess = res => ({
  type: GET_COURSES_SUCCESS,
  payload: res
});

export const getCoursesError = error => ({
  type: GET_COURSES_ERROR,
  payload: error
});
