import { takeLatest, call } from 'redux-saga/effects';
import Request from 'utils/request';
import { GET_SYLLABUS, getSyllabusSuccess, getSyllabusError } from 'actions';

function* getSyllabus(action) {
  yield call(
    Request.get(
      `/courses/${action.payload}/syllabus`,
      getSyllabusSuccess,
      getSyllabusError
    )
  );
}

export default function* getSyllabusSaga() {
  yield takeLatest(GET_SYLLABUS, getSyllabus);
}
