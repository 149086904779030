import {
  CREATE_SUBMISSION,
  SUBMISSION_SUCCESS,
  SUBMISSION_ERROR
} from 'actions';

const initialState = {
  loading: false,
  error: null,
  data: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_SUBMISSION:
      return { ...state, loading: true };
    case SUBMISSION_SUCCESS:
      return { ...state, loading: false, data: action.payload.body };
    case SUBMISSION_ERROR:
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
};
