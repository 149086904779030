import { LOGIN, LOGIN_SUCCESS, LOGIN_ERROR } from 'actions';

const initialState = {
  loading: false,
  error: null,
  loggedIn: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return { ...state, loading: true };
    case LOGIN_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        loggedIn: true
      };
    case LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.data.error
      };
    default:
      return state;
  }
};
