import palette from '../palette';

export default {
  outlined: {
    border: `2px solid ${palette.grey.faint}`,
    borderRadius: 5,
    '&:hover': {
      boxShadow: '2px 2px 8px 2px #cccccc9e'
    }
  }
};
