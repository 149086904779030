import { GET_PROFILE, GET_PROFILE_SUCCESS, GET_PROFILE_ERROR } from 'actions';

const initialState = {
  loading: false,
  error: null,
  profile: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE:
      return { ...state, loading: true };
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.payload.body.data
      };
    case GET_PROFILE_ERROR:
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
};
