import { takeLatest, call } from 'redux-saga/effects';
import Request from 'utils/request';
import { GET_COURSE, getCourseSuccess, getCourseError } from 'actions';

function* getCourse(action) {
  yield call(
    Request.get(`/courses/${action.payload}`, getCourseSuccess, getCourseError)
  );
}

export default function* getCourseSaga() {
  yield takeLatest(GET_COURSE, getCourse);
}
