// @flow
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile } from 'actions';
import * as R from 'ramda';

export default function useProfile() {
  const dispatch = useDispatch();
  const { profile, loading, error } = useSelector(
    state => state.profileReducer
  );

  useEffect(() => {
    // eslint-disable-next-line
    R.isEmpty(profile) && !error && !loading && dispatch(getProfile());
  }, [dispatch, profile, error, loading]);

  const isAuthenticated = !error && !R.isEmpty(profile);
  return { profile, loading, isAuthenticated };
}
