import { fork } from 'redux-saga/effects';
import loginSaga from './login';
import signupSaga from './signup';
import coursesSaga from './courses';
import courseSaga from './Course';
import syllabusSaga from './Syllabus';
import contentSaga from './Content';
import enrollmentsSaga from './Enrollment';
import progressSaga from './Progress';
import profileSaga from './Profile';
import unitSaga from './Unit';

export default function* rootSaga() {
  yield fork(loginSaga);
  yield fork(signupSaga);
  yield fork(coursesSaga);
  yield fork(courseSaga);
  yield fork(contentSaga);
  yield fork(enrollmentsSaga);
  yield fork(progressSaga);
  yield fork(syllabusSaga);
  yield fork(unitSaga);
  yield fork(profileSaga);
}
