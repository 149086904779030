import { takeLatest, call, put } from 'redux-saga/effects';
import { get } from 'utils/apiClient';
import { GET_COURSES, getCoursesSuccess, getCoursesError } from 'actions';

function* getCourses() {
  try {
    const response = yield call(get, '/courses');
    yield put(getCoursesSuccess(response.data));
  } catch (error) {
    yield put(getCoursesError(error));
  }
}

export default function* getCoursesSaga() {
  yield takeLatest(GET_COURSES, getCourses);
}
