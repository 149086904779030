export const PROGRESS = 'containers/progress/PROGRESS';
export const GET_PROGRESS = 'containers/progress/GET_PROGRESS';
export const PROGRESS_SUCCESS = 'containers/progress/PROGRESS_SUCCESS';
export const PROGRESS_ERROR = 'containers/progress/PROGRESS_ERROR';

export const progress = data => ({
  type: PROGRESS,
  payload: data
});

export const progressSuccess = res => ({
  type: PROGRESS_SUCCESS,
  payload: res
});

export const progressError = error => ({
  type: PROGRESS_ERROR,
  payload: error
});
