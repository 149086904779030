// @flow
import React from 'react';
import { Switch } from 'react-router-dom';

import { HeaderLoader, loadComponent } from 'components/common/Loader';
import AuthenticationLayout from 'components/Layout/AuthenticationLayout';
import {
  Authenticated,
  UnAuthenticated
} from 'components/common/Authentication';

const Login = loadComponent(() => import('containers/Login'));
const Signup = loadComponent(() => import('containers/Signup'));
const Home = loadComponent(() => import('containers/Home'));
const CourseDetail = loadComponent(() => import('containers/CourseDetail'));
const Learn = loadComponent(() => import('containers/Learn'));

const App = () => (
  <React.Fragment>
    <HeaderLoader />
    <Switch>
      <Authenticated exact path="/" component={Home} />
      <Authenticated path="/courses/:slug" component={CourseDetail} />
      <Authenticated
        path="/learn/:courseSlug/:chapterSlug/:unitSlug"
        component={Learn}
      />
      <AuthenticationLayout>
        <UnAuthenticated path="/login" component={Login} />
        <UnAuthenticated path="/signup" component={Signup} />
      </AuthenticationLayout>
    </Switch>
  </React.Fragment>
);

export default App;
