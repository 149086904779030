// @flow
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getAuthToken } from 'utils/authToken';
import useProfile from './useProfile';

// TODO
// Also, when we enter to the home page or do the login / signup
// We should also get the user information
// And, check if the authToken is valid
export const Authenticated = props => {
  const authToken = getAuthToken();
  useProfile();
  return <>{authToken ? <Route {...props} /> : <Redirect to="/login" />}</>;
};

export const UnAuthenticated = props => {
  const authToken = getAuthToken();
  return <>{!authToken ? <Route {...props} /> : <Redirect to="/" />}</>;
};
