import { takeLatest, call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { post } from 'utils/apiClient';
import { SIGNUP, SIGNUP_SUCCESS, signupSuccess, signupError } from 'actions';
import { setAuthToken } from 'utils/authToken';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

function* signup(action) {
  try {
    yield put(showLoading());
    const response = yield call(post, '/signup', { user: action.payload });
    yield put(signupSuccess(response));
  } catch (error) {
    yield put(signupError(error));
  } finally {
    yield put(hideLoading());
  }
}

function* onSuccess(action) {
  setAuthToken(action.payload.headers.Authorization.split(' ')[1]);
  yield put(push('/'));
}

export default function* signupSaga() {
  yield takeLatest(SIGNUP, signup);
  yield takeLatest(SIGNUP_SUCCESS, onSuccess);
}
