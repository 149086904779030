export default {
  fontFamily: 'Rubik',
  h1: {
    fontWeight: 700,
    fontSize: 96
  },
  h2: {
    fontWeight: 700,
    fontSize: 60
  },
  h3: {
    fontWeight: 700,
    fontSize: 48
  },
  h4: {
    fontWeight: 700,
    fontSize: 24
  },
  h5: {
    fontWeight: 700,
    fontSize: 20
  },
  h6: {
    fontWeight: 700,
    fontSize: 16
  },
  body1: {
    fontSize: 16
  },
  button: {
    fontWeight: 700,
    fontSize: 14
  },
  caption: {
    fontWeight: 500,
    fontSize: 12
  }
};
