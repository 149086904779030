import { takeLatest, call } from 'redux-saga/effects';
import Request from 'utils/request';
import { PROGRESS, progressSuccess, progressError } from 'actions';

function* progress(action) {
  yield call(
    Request.post('/progress', progressSuccess, progressError, action.payload)
  );
}

export default function* progressSaga() {
  yield takeLatest(PROGRESS, progress);
}
