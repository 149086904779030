export default {
  gutters: {
    '@media (min-width: 1280px)': {
      marginLeft: 'auto',
      marginRight: 'auto',
      minWidth: '1280px'
    }
  }
};

// xs, extra-small: 0px
// sm, small: 600px
// md, medium: 960px
// lg, large: 1280px
// xl, extra-large: 1920px
