export const GET_PROFILE = 'containers/Course/GET_PROFILE';
export const GET_PROFILE_SUCCESS = 'containers/Course/GET_PROFILE_SUCCESS';
export const GET_PROFILE_ERROR = 'containers/Course/GET_PROFILE_ERROR';

export const getProfile = data => ({
  type: GET_PROFILE,
  payload: data
});

export const getProfileSuccess = res => ({
  type: GET_PROFILE_SUCCESS,
  payload: res
});

export const getProfileError = error => ({
  type: GET_PROFILE_ERROR,
  payload: error
});
