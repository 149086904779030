// @flow
import React from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import palette from './palette';
import typography from './typography';
import zIndex from './zIndex';

import MuiButton from './overrides/MuiButton';
import MuiPaper from './overrides/MuiPaper';
import MuiToolbar from './overrides/MuiToolbar';
import MuiListItemText from './overrides/MuiListItemText';

export default function useTheme() {
  // TODO: for darkTheme switch based on user's browser preference
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  // TODO: customise palette: type: dark/light in future
  const theme = React.useMemo(
    () =>
      createMuiTheme({
        typography,
        overrides: {
          MuiButton,
          MuiToolbar,
          MuiPaper,
          MuiListItemText
        },
        zIndex,
        palette
      }),
    []
  );
  return theme;
}
