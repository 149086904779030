export const GET_COURSE = 'containers/Home/GET_COURSE';
export const GET_COURSE_SUCCESS = 'containers/Home/GET_COURSE_SUCCESS';
export const GET_COURSE_ERROR = 'containers/Home/GET_COURSE_ERROR';
export const getCourse = data => ({
  type: GET_COURSE,
  payload: data
});

export const getCourseSuccess = res => ({
  type: GET_COURSE_SUCCESS,
  payload: res
});

export const getCourseError = error => ({
  type: GET_COURSE_ERROR,
  payload: error
});
