import { GET_COURSES, GET_COURSES_SUCCESS, GET_COURSES_ERROR } from 'actions';

const initialState = {
  loading: false,
  error: null,
  courses: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_COURSES:
      return { ...state, loading: true };
    case GET_COURSES_SUCCESS:
      return { ...state, loading: false, courses: action.payload.data };
    case GET_COURSES_ERROR:
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
};
