import { takeLatest, call, put } from 'redux-saga/effects';
import Request from 'utils/request';
import {
  GET_CONTENT,
  getContentSuccess,
  getContentError,
  PROGRESS
} from 'actions';

function* getContent(action) {
  const { courseSlug, unitSlug, chapterSlug } = action.payload;
  yield call(
    Request.get(
      `/courses/${courseSlug}/chapters/${chapterSlug}/topics/${unitSlug}`,
      getContentSuccess,
      getContentError
    )
  );
  // TODO: FIXME: does it needs error handeling ??
  yield put({ type: PROGRESS, payload: { slug: unitSlug } });
}
export default function* getContentSaga() {
  yield takeLatest(GET_CONTENT, getContent);
}
