import { takeLatest, call } from 'redux-saga/effects';
import Request from 'utils/request';
import { CREATE_SUBMISSION, submissionSuccess, submissionError } from 'actions';

function* submission(action) {
  yield call(
    Request.post(
      `/units/${action.payload}/submit`,
      submissionSuccess,
      submissionError
    )
  );
}

export default function* submissionSaga() {
  yield takeLatest(CREATE_SUBMISSION, submission);
}
