import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import logger from 'redux-logger';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';

import createRootReducer from './reducers';
import saga from './saga';

const sagaMiddleware = createSagaMiddleware();

export const browserHistory = createBrowserHistory();

const composeEnhancers =
  typeof window === 'object' &&
  // eslint-disable-next-line no-underscore-dangle
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? // eslint-disable-next-line no-underscore-dangle
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

export default function configureStore() {
  const store = createStore(
    createRootReducer(browserHistory),
    composeEnhancers(
      applyMiddleware(logger, sagaMiddleware, routerMiddleware(browserHistory))
    )
  );

  sagaMiddleware.run(saga);

  return store;
}
