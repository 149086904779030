export default {
  primary: {
    main: '#0829CC',
    hover: '#1138F6'
  },
  secondary: {
    main: '#8CBA51',
    hover: '#639E23'
  },
  error: {
    main: '#FF453A'
  },
  warning: {
    main: '#F6C243'
  },
  success: {
    main: '#52c41a'
  },
  info: {
    main: '#43BFF6'
  },
  grey: {
    main: '#191919',
    mild: '#484848',
    light: '#D2D2D2',
    faint: '#E3E3E3'
  },
  common: {
    white: '#fff',
    black: '#191919'
  }
};
