import { SIGNUP, SIGNUP_SUCCESS, SIGNUP_ERROR } from 'actions';

const initialState = {
  loading: false,
  errors: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP:
      return {
        ...state,
        loading: true
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: null
      };
    case SIGNUP_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload.data.errors
      };
    default:
      return state;
  }
};
