import { takeLatest, call } from 'redux-saga/effects';
import Request from 'utils/request';
import {
  GET_ENROLLMENT,
  getEnrollmentSuccess,
  getEnrollmentError,
  POST_ENROLLMENT,
  postEnrollmentSuccess,
  postEnrollmentError
} from 'actions';

function* getEnrollment(action) {
  yield call(
    Request.get(`/enrollments/`, getEnrollmentSuccess, getEnrollmentError, {
      enrollments: action.payload
    })
  );
}

function* postEnrollment(action) {
  yield call(
    Request.post(`/enrollments/`, postEnrollmentSuccess, postEnrollmentError, {
      enrollment: action.payload
    })
  );
}

export default function* enrollmentsSaga() {
  yield takeLatest(GET_ENROLLMENT, getEnrollment);
  yield takeLatest(POST_ENROLLMENT, postEnrollment);
}
