import { call, put } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { getAuthToken } from 'utils/authToken';

export default class Request {
  static request(path, options, onSuccess, onError, data) {
    const request = () => {
      let authToken = getAuthToken();
      const authOptions = authToken
        ? { Authorization: `Bearer ${authToken}` }
        : {};

      const allOptions = {
        ...(options || {}),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          ...authOptions
        },
        body: data && JSON.stringify(data)
      };
      const url = process.env.REACT_APP_API_ENDPOINT + path;
      const response = fetch(url, allOptions);
      let statusCode;

      return response
        .then(res => {
          statusCode = res.status;
          authToken = res.headers.get('Authorization');
          return res.json();
        })
        .then(res => ({
          data: {
            body: res,
            header: { Authorization: authToken }
          },
          statusCode
        }));
    };

    return function* loader() {
      try {
        yield put(showLoading());
        const res = yield call(request);
        if (res.statusCode < 400) {
          yield put(onSuccess(res.data));
        } else {
          yield put(onError(res.data));
        }
      } catch (e) {
        yield put(onError(e));
      } finally {
        yield put(hideLoading());
      }
    };
  }

  static get(path, onSuccess, onError) {
    const options = {
      method: 'GET'
    };

    return this.request(path, options, onSuccess, onError);
  }

  static post(path, onSuccess, onError, data) {
    const options = {
      method: 'POST'
    };

    return this.request(path, options, onSuccess, onError, data);
  }

  // function to do request on arbitary url
  static fetchUrl(baseUrl, path, onSuccess, onError) {
    const request = () => {
      const options = {
        method: 'GET'
      };
      let statusCode;
      const url =
        baseUrl === ''
          ? process.env.REACT_APP_API_ENDPOINT + path
          : baseUrl + path;
      const response = fetch(url, options);
      return response
        .then(res => {
          statusCode = res.status;
          return res.text();
        })
        .then(res => ({
          data: {
            body: res
          },
          statusCode
        }));
    };
    return function* loader() {
      try {
        yield put(showLoading());
        const res = yield call(request);
        if (res.statusCode < 400) {
          yield put(onSuccess(res.data));
        } else {
          yield put(onError(res.data));
        }
      } catch (e) {
        yield put(onError(e));
      } finally {
        yield put(hideLoading());
      }
    };
  }
}
