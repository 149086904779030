import palette from '../palette';

export default {
  containedPrimary: {
    '&:hover': {
      color: palette.common.white,
      backgroundColor: palette.primary.hover
    }
  },
  outlinedPrimary: {
    borderWidth: 2,
    '&:hover': {
      color: palette.common.white,
      backgroundColor: palette.primary.hover
    }
  },
  textPrimary: {
    '&:hover': {
      color: palette.common.white,
      backgroundColor: palette.primary.hover
    }
  },
  sizeSmall: {
    padding: '8px 30px'
  },
  root: {
    fontSize: '14px',
    borderRadius: '5px',
    padding: '16px 22px'
  },
  sizeLarge: {
    padding: '20px'
  }
};
